/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Divider, Drawer, Hidden, List, ListSubheader, makeStyles } from "@material-ui/core";
import {
  Activity as ActivityIcon,
  Award as AwardIcon,
  BarChart as BarChartIcon,
  Briefcase as BriefcaseIcon,
  FileText as FileTextIcon,
  Film as VideoLibIcon,
  Globe as GlobeIcon,
  Grid as GridIcon,
  Lock as LockIcon,
  Mail as MailIcon,
  ShoppingBag as ShoppingBagIcon,
  Star as StarIcon,
  User as UserIcon,
  UserPlus,
  Users as UsersIcon,
  Video as VideoIcon
} from "react-feather";
import {
  Airplay as HeroIcon,
  HelpOutline as TipTricksIcon,
  Message as MessageIcon,
  School as SchoolIcon,
  ShowChart as ShowChartIcon
} from "@material-ui/icons";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";

const sections = [
  {
    subheader: "Reports",
    for: ["admin", "moderator"],
    items: [
      {
        title: "Dashboard",
        icon: BarChartIcon,
        href: "/app/dashboard"
      },
      {
        title: "Email",
        icon: MailIcon,
        href: "/app/email"
      },
      {
        title: "Video",
        icon: VideoIcon,
        href: "/app/video"
      }
    ]
  },
  {
    subheader: "JTI Smart",
    for: ["admin", "moderator"],
    items: [
      {
        title: "Dashboard",
        icon: ShowChartIcon,
        href: "/app/jti-smart"
      },
      {
        title: "Courses",
        icon: SchoolIcon,
        href: "/app/jti-smart/courses",
        items: [
          {
            title: "All courses",
            href: "/app/jti-smart/courses"
          },
          {
            title: "Add new course",
            href: "/app/jti-smart/courses/create"
          }
        ]
      },
      {
        title: "Tip&tricks",
        icon: TipTricksIcon,
        href: "/app/jti-smart/tips-tricks"
      },
      {
        title: "Hero banner",
        icon: HeroIcon,
        href: "/app/jti-smart/hero"
      }
    ]
  },
  {
    subheader: "Portal",
    for: ["admin", "moderator"],
    items: [
      {
        title: "Activations",
        icon: ActivityIcon,
        href: "/app/activations",
        items: [
          {
            title: "List",
            href: "/app/activations"
          },
          {
            title: "Create activation",
            href: "/app/activations/create"
          }
        ]
      },
      {
        title: "Members",
        icon: UsersIcon,
        href: "/app/members",
        items: [
          {
            title: "Active members",
            href: "/app/members"
          },
          {
            title: "Deactivated members",
            href: "/app/members/deactivated"
          }
        ]
      },
      {
        title: "Gamification",
        icon: AwardIcon,
        href: "/app/prize-periods",
        items: [
          {
            title: "Prize periods",
            href: "/app/prize-periods"
          },
          {
            title: "Prizes",
            href: "/app/prizes"
          }
        ]
      },
      {
        title: "Messaging",
        icon: MessageIcon,
        href: "/app/sms",
        items: [
          {
            title: "Sms",
            href: "/app/sms"
          },
          {
            title: "Push notifications",
            href: "/app/push-notifications"
          }
        ]
      },
      {
        title: "Featured",
        icon: StarIcon,
        href: "/app/featured"
      },
      {
        title: "Articles",
        icon: FileTextIcon,
        href: "/app/articles"
      },
      {
        title: "Video gallery",
        icon: VideoLibIcon,
        href: "/app/videos"
      },
      {
        title: "Affiliate",
        icon: UserPlus,
        href: "/app/affiliate"
      }
    ]
  },
  {
    subheader: "Management",
    for: ["admin", "moderator"],
    items: [
      {
        title: "Points of sale",
        icon: ShoppingBagIcon,
        href: "/app/management/pos"
      },
      {
        title: "Salesmen",
        icon: BriefcaseIcon,
        href: "/app/management/salesmen"
      },
      {
        title: "Retailers",
        icon: GlobeIcon,
        href: "/app/management/retailers"
      },
      {
        title: "Planogrami",
        icon: GridIcon,
        href: "/app/management/planograms"
      }
    ]
  },
  {
    subheader: "Admin",
    for: ["admin"],
    items: [
      {
        title: "Registration codes",
        icon: LockIcon,
        href: "/app/code-batches"
      },
      {
        title: "Users",
        icon: UsersIcon,
        href: "/app/users"
      }
    ]
  },
  {
    subheader: "Settings",
    for: ["admin", "moderator"],
    items: [
      {
        title: "Account",
        href: "/app/account",
        icon: UserIcon
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map(
            (section) => (
              // section.for.includes(user.user.role) && (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            )
            // )
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
