import React, { forwardRef, useEffect, useCallback, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import track from 'src/utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f4f6f8',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

interface PageProps {
  children: ReactNode;
  title?: string;
  className?: string;
}

const Page: React.FC<PageProps> = ({
  children,
  title = '',
  className = '',
  ...rest
}) => {
  const location = useLocation();
  const classes = useStyles();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div className={classes.root} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default Page;
