import React from 'react';
import PropTypes from 'prop-types';
const Logo = ({ iswhite }) => {
  const src = iswhite ? '/static/logo_white.svg' : '/static/logo.svg';
  return <img alt="Logo" src={src} style={{ maxHeight: '40px' }} />;
};

Logo.propTypes = {
  iswihte: PropTypes.bool
};

export default Logo;
