import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: LoginView
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },

      {
        exact: true,
        path: '/app/management/pos',
        component: lazy(() => import('src/views/pos/PosListView'))
      },
      {
        exact: true,
        path: '/app/management/pos/create',
        component: lazy(() => import('src/views/pos/PosCreateView'))
      },
      {
        exact: true,
        path: '/app/management/pos/:posId',
        component: lazy(() => import('src/views/pos/PosDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/pos/:posId/edit',
        component: lazy(() => import('src/views/pos/PosEditView'))
      },

      {
        exact: true,
        path: '/app/management/salesmen',
        component: lazy(() => import('src/views/salesman/SalesmanListView'))
      },
      {
        exact: true,
        path: '/app/management/salesmen/create',
        component: lazy(() => import('src/views/salesman/SalesmanCreateView'))
      },
      {
        exact: true,
        path: '/app/management/salesmen/:id',
        component: lazy(() => import('src/views/salesman/SalesmanDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/salesmen/:id/edit',
        component: lazy(() => import('src/views/salesman/SalesmanEditView'))
      },

      {
        exact: true,
        path: '/app/management/planograms',
        component: lazy(() => import('src/views/planograms/ListView'))
      },
      {
        exact: true,
        path: '/app/management/planograms/create',
        component: lazy(() => import('src/views/planograms/CreateView'))
      },
      {
        exact: true,
        path: '/app/management/planograms/:id',
        component: lazy(() => import('src/views/planograms/DetailsView'))
      },
      {
        exact: true,
        path: '/app/management/planograms/:id/edit',
        component: lazy(() => import('src/views/planograms/EditView'))
      },

      {
        exact: true,
        path: '/app/management/retailers',
        component: lazy(() => import('src/views/retailer/RetailerListView'))
      },
      {
        exact: true,
        path: '/app/management/retailers/create',
        component: lazy(() => import('src/views/retailer/RetailerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/retailers/:id',
        component: lazy(() => import('src/views/retailer/RetailerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/retailers/:id/edit',
        component: lazy(() => import('src/views/retailer/RetailerEditView'))
      },

      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/pos" />
      },

      {
        exact: true,
        path: '/app/code-batches',
        component: lazy(() => import('src/views/codes/CodeBatchListView'))
      },
      {
        exact: true,
        path: '/app/code-batches/create',
        component: lazy(() => import('src/views/codes/CodeBatchCreateView'))
      },
      {
        exact: true,
        path: '/app/code-batches/:id',
        component: lazy(() => import('src/views/codes/CodeBatchView'))
      },

      {
        exact: true,
        path: '/app/users',
        component: lazy(() => import('src/views/users/UserListView'))
      },
      {
        exact: true,
        path: '/app/users/create',
        component: lazy(() => import('src/views/users/UserCreateView'))
      },
      {
        exact: true,
        path: '/app/users/:id',
        component: lazy(() => import('src/views/users/UserView'))
      },
      {
        exact: true,
        path: '/app/users/:id/edit',
        component: lazy(() => import('src/views/users/UserEditView'))
      },

      {
        exact: true,
        path: '/app/members/deactivated',
        component: lazy(() => import('src/views/members/DeactivatedMembers'))
      },

      {
        exact: true,
        path: '/app/members',
        component: lazy(() => import('src/views/members/MemberListView'))
      },
      {
        exact: true,
        path: '/app/members/:id/edit',
        component: lazy(() => import('src/views/members/MemberEditView'))
      },
      {
        exact: true,
        path: '/app/members/:id',
        component: lazy(() => import('src/views/members/MemberView'))
      },

      {
        exact: true,
        path: '/app/activations',
        component: lazy(() =>
          import('src/views/activations/ActivationListView')
        )
      },
      {
        exact: true,
        path: '/app/activations/create',
        component: lazy(() =>
          import('src/views/activations/ActivationCreateView')
        )
      },
      {
        exact: true,
        path: '/app/affiliate',
        component: lazy(() => import('src/views/affiliate'))
      },
      {
        exact: true,
        path: '/app/activations/:id',
        component: lazy(() => import('src/views/activations/ActivationView'))
      },
      {
        exact: true,
        path: '/app/activations/:id/edit',
        component: lazy(() =>
          import('src/views/activations/ActivationEditView')
        )
      },

      {
        exact: true,
        path: '/app/prize-periods',
        component: lazy(() =>
          import('src/views/prize_periods/PrizePeriodsOverview')
        )
      },
      {
        exact: true,
        path: '/app/prize-periods/list',
        component: lazy(() =>
          import('src/views/prize_periods/PrizePeriodsList')
        )
      },
      {
        exact: true,
        path: '/app/prize-periods/:id',
        component: lazy(() =>
          import('src/views/prize_periods/SinglePrizePeriod/PrizePeriodPage')
        )
      },

      {
        exact: true,
        path: '/app/gamification/awards',
        component: lazy(() => import('src/views/awards/ListView'))
      },
      {
        exact: true,
        path: '/app/gamification/awards/create',
        component: lazy(() => import('src/views/awards/CreateView'))
      },
      {
        exact: true,
        path: '/app/gamification/awards/:id',
        component: lazy(() => import('src/views/awards/DetailsView'))
      },
      {
        exact: true,
        path: '/app/gamification/awards/:id/edit',
        component: lazy(() => import('src/views/awards/EditView'))
      },

      {
        exact: true,
        path: '/app/featured',
        component: lazy(() => import('src/views/featured/ListView'))
      },
      {
        exact: true,
        path: '/app/featured/create',
        component: lazy(() => import('src/views/featured/CreateView'))
      },
      {
        exact: true,
        path: '/app/featured/:id',
        component: lazy(() => import('src/views/featured/DetailsView'))
      },
      {
        exact: true,
        path: '/app/featured/:id/edit',
        component: lazy(() => import('src/views/featured/EditView'))
      },

      {
        exact: true,
        path: '/app/articles',
        component: lazy(() => import('src/views/articles/ListView'))
      },
      {
        exact: true,
        path: '/app/articles/create',
        component: lazy(() => import('src/views/articles/CreateView'))
      },
      {
        exact: true,
        path: '/app/articles/:id',
        component: lazy(() => import('src/views/articles/SingleView'))
      },
      {
        exact: true,
        path: '/app/articles/:id/edit',
        component: lazy(() => import('src/views/articles/EditView'))
      },

      {
        exact: true,
        path: '/app/videos',
        component: lazy(() => import('src/views/videos/ListView'))
      },
      {
        exact: true,
        path: '/app/videos/create',
        component: lazy(() => import('src/views/videos/CreateView'))
      },
      {
        exact: true,
        path: '/app/videos/:id',
        component: lazy(() => import('src/views/videos/SingleView'))
      },
      {
        exact: true,
        path: '/app/videos/:id/edit',
        component: lazy(() => import('src/views/videos/EditView'))
      },

      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/dashboard'))
      },

      {
        exact: true,
        path: '/app/email',
        component: lazy(() => import('src/views/email'))
      },

      {
        exact: true,
        path: '/app/video',
        component: lazy(() => import('src/views/video'))
      },

      // JTI smart
      {
        exact: true,
        path: '/app/jti-smart',
        component: lazy(() => import('src/views/jti-smart/Dashboard'))
      },
      {
        exact: true,
        path: '/app/jti-smart/courses',
        component: lazy(() => import('src/views/jti-smart/courses/CoursesList'))
      },
      {
        exact: true,
        path: '/app/jti-smart/courses/create',
        component: lazy(() =>
          import('src/views/jti-smart/courses/CourseCreate')
        )
      },
      {
        exact: true,
        path: '/app/jti-smart/courses/:id',
        component: lazy(() => import('src/views/jti-smart/courses/Course'))
      },
      {
        exact: true,
        path: '/app/jti-smart/courses/:id/edit',
        component: lazy(() => import('src/views/jti-smart/courses/CourseEdit'))
      },

      {
        exact: true,
        path: '/app/jti-smart/tips-tricks',
        component: lazy(() => import('src/views/jti-smart/TipTricks'))
      },
      {
        exact: true,
        path: '/app/jti-smart/tips-tricks/create',
        component: lazy(() =>
          import('src/views/jti-smart/TipTricks/TipTrickCreate')
        )
      },
      {
        exact: true,
        path: '/app/jti-smart/tips-tricks/:id/edit',
        component: lazy(() =>
          import('src/views/jti-smart/TipTricks/TipTrickUpdate')
        )
      },
      {
        exact: true,
        path: '/app/jti-smart/hero',
        component: lazy(() => import('src/views/jti-smart/hero/SmartHeroShow'))
      },
      {
        exact: true,
        path: '/app/jti-smart/hero/edit',
        component: lazy(() => import('src/views/jti-smart/hero/SmartHeroEdit'))
      },

      {
        exact: true,
        path: '/app/prizes',
        component: lazy(() => import('src/views/prizes/PrizeList'))
      },
      {
        exact: true,
        path: '/app/prizes/create',
        component: lazy(() => import('src/views/prizes/PrizeCreate'))
      },
      {
        exact: true,
        path: '/app/prizes/:id/edit',
        component: lazy(() => import('src/views/prizes/PrizeUpdate'))
      },

      // Push notifications

      {
        exact: true,
        path: '/app/push-notifications',
        component: lazy(() => import('src/views/push_notifications/PushList'))
      },
      {
        exact: true,
        path: '/app/push-notifications/create',
        component: lazy(() => import('src/views/push_notifications/PushCreate'))
      },
      {
        exact: true,
        path: '/app/push-notifications/:id',
        component: lazy(() => import('src/views/push_notifications/PushView'))
      },
      {
        exact: true,
        path: '/app/push-notifications/:id/edit',
        component: lazy(() => import('src/views/push_notifications/PushUpdate'))
      },

      // SMS

      {
        exact: true,
        path: '/app/sms',
        component: lazy(() => import('src/views/sms/SmsList'))
      },
      {
        exact: true,
        path: '/app/sms/create',
        component: lazy(() => import('src/views/sms/SmsCreate'))
      },
      {
        exact: true,
        path: '/app/sms/:id',
        component: lazy(() => import('src/views/sms/SmsView'))
      },

      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: LoginView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
