import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.css';
import { SWRConfig } from 'swr';
import useApi from 'src/hooks/useApi';

const history = createBrowserHistory();

const App = () => {
  const theme = createTheme({
    theme: 'LIGHT'
  });

  const { swrFetcher } = useApi();

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <SWRConfig
          value={{
            fetcher: swrFetcher,
            dedupingInterval: 60 * 60 * 1000 // Re-fetch data only after 60 minutes. Can be overriden with each instance
          }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <SnackbarProvider dense maxSnack={3}>
              <Router history={history}>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  {renderRoutes(routes)}
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </SWRConfig>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
