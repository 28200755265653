import { useState } from 'react';
import axios from '../utils/customAxios';
const fileDownload = require('js-file-download');
/**
 * This hook can be used in any component and provides a wrapper
 * to make API calls. It uses custom axios instance, that has some global properties
 * for interacting with our API.
 */

const useApi = (endpoint) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const buildUrl = ({
    filters = [],
    sorts = [],
    includes = [],
    page,
    perPage
  }) => {
    const params = {};

    /**
     * Receives array of filters objects as such:
     * {
     *    by: "name"
     *    value: "test"
     * }
     *  */

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      params[`filter[${filter.by}]`] = filter.value;
    }

    /**
     * Receives array of sorts objects as such:
     * {
     *    by: "created_at"
     *    direction: "desc"
     * }
     * should convert direction to prepend "-" if is desc
     *  */

    for (let i = 0; i < sorts.length; i++) {
      const sort = sorts[i];
      params[`sort`] = `${sort.direction.toLowerCase() === 'desc' ? '-' : ''}${
        sort.by
      }`;
    }

    /**
     * Receives array of includes as:
     * ["member", "activation"]
     *  */
    params[`include`] = includes.join(',');

    if (page) params['page'] = page;
    if (perPage) params['per_page'] = perPage;

    const urlParams = new URLSearchParams(params);

    if (endpoint) return endpoint + '?' + urlParams.toString();
    else return '?' + urlParams.toString();
  };

  const download = (url) => {
    return new Promise((resolve, reject) => {
      if (isDownloading) return reject('Download in progress...');
      setIsDownloading(true);
      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          // Trigger download from response.data binary data
          // content-disposition header contains filename eg.: content-disposition: "attachment; filename=points_of_sale.xlsx"
          const filename = response.headers['content-disposition'].split(
            'filename='
          );
          fileDownload(
            response.data,
            filename[1].replace(/"/gi, '') || 'file.xlsx'
          );
          resolve('File downloaded.');
          setIsDownloading(false);
        })
        .catch((error) => {
          reject('Something went wrong while downloading.');
          setIsDownloading(false);
        });
    });
  };

  const upload = (files, endpoint) => {
    return new Promise((resolve, reject) => {
      if (isUploading) return reject('Upload in progress...');
      setIsUploading(true);
      let formData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });
      axios
        .post(endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          resolve(response.data.data);
          setIsUploading(false);
        })
        .catch((error) => {
          reject('Something went wrong while uploading.');
          setIsUploading(false);
        });
    });
  };

  const remove = ({ title, endpoint, confirmationText = '' }) => {
    return new Promise((resolve, reject) => {
      const confirmed = window.confirm(
        confirmationText
          ? confirmationText
          : 'Are you sure you want to delete ' + title + '?'
      );
      if (confirmed) {
        return resolve(axios.delete(endpoint));
      } else {
        reject();
      }
    });
  };

  const swrFetcher = (url) => axios.get(url).then((res) => res.data);

  // Expose axios methods (get, post, put, ...) and other methods for API interaction
  return { ...axios, buildUrl, download, remove, upload, swrFetcher };
};

export default useApi;
