export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const URLS = {
  PUBLIC: 'https://nas-partner.si',
  API: 'https://api.nas-partner.si',
  ADMIN: 'https://admin.nas-partner.si',
  AKTIVACIJE: 'https://aktivacije.nas-partner.si',
  SMART: 'https://smart.nas-partner.si'
};
