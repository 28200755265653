import { useEffect, useState } from 'react';
import { load } from 'recaptcha-v3';
import { captchaSiteKey } from '../utils/captcha';

export default function useCaptcha() {
  const [recaptcha, setRecaptcha] = useState();
  useEffect(async () => {
    const recaptcha = await load(captchaSiteKey, {
      autoHideBadge: true,
      explicitRenderParameters: {
        size: 'invisible'
      }
    });
    setRecaptcha(recaptcha);
  }, []);

  return { recaptcha };
}
